import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import style from './about.module.css'

const Bold = ({ children }) => <span>{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

class RootIndex extends React.Component {
  render() {
    const title = get(this, 'props.data.contentfulPage.title')
    const content = get(this, 'props.data.contentfulPage.content')
    const image = get(this, 'props.data.contentfulPage.image.file.url')

    return (
      <Layout
        location={this.props.location}
        packageTypes={this.props.data.allContentfulPackageType}
        photoshoots={this.props.data.contentfulEntriesGroup}
      >
        <Helmet>
          <meta
            name="description"
            content="Get to know more about Juliana de Souza."
          />
        </Helmet>

        <article className={style.article}>
          <div className={style.imageDesktop}>
            <img src={image} />
          </div>

          <div>
            <div className={style.header}>
              <div className={style.imageMobile}>
                <img src={image} />
              </div>

              <h1 className={style.title}>{title}</h1>
            </div>

            <div className={style.text}>
              {documentToReactComponents(content.json, options)}
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query AboutPage {
    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }

    contentfulPage(slug: { eq: "/about" }) {
      title
      content {
        json
      }
      image {
        file {
          url
        }
      }
    }
  }
`
